import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from '@src/auth/guard';
// layouts
import DashboardLayout from '@layouts/dashboard';
// components
import { LoadingScreen } from '@components/loading-screen';

// ----------------------------------------------------------------------

const DiscountNewPage = lazy(() => import('@pages/discount/new'));
const DiscountListPage = lazy(() => import('@pages/discount/list'));

// ----------------------------------------------------------------------

export const discountsRoutes = [
  {
    path: 'discounts',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DiscountListPage />, index: true },
      { path: 'new', element: <DiscountNewPage /> },
    ],
  },
];
