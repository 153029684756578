import React, {
  createContext,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

import { SortColumn } from '@redux/interfaces/commonEnums';
import { DiscountStatus } from '@redux/interfaces/user/enum';

interface DiscountsContextProps {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  dense: boolean;
  setDense: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  status: DiscountStatus | 'all';
  setStatus: Dispatch<SetStateAction<DiscountStatus | 'all'>>;
  sortColumnValue: string;
  setSortColumnValue: Dispatch<SetStateAction<string>>;
  sortColumnDirection: number;
  setSortColumnDirection: Dispatch<SetStateAction<number>>;
  resetDiscountsContext: Function;
}

export const DiscountsContext = createContext<DiscountsContextProps>({
  currentPage: 1,
  setCurrentPage: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
  dense: false,
  setDense: () => {},
  status: 'all',
  setStatus: () => {},
  searchValue: '',
  setSearchValue: () => {},
  sortColumnValue: SortColumn.NAME,
  setSortColumnValue: () => {},
  sortColumnDirection: 1,
  setSortColumnDirection: () => {},
  resetDiscountsContext: () => {},
});

interface DiscountsProviderProps {
  children: React.ReactNode;
}

export const DiscountsProvider: React.FC<DiscountsProviderProps> = ({
  children,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [dense, setDense] = useState<boolean>(false);
  const [status, setStatus] = useState<DiscountStatus | 'all'>('all');
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortColumnValue, setSortColumnValue] = useState<string>(
    SortColumn.NAME
  );
  const [sortColumnDirection, setSortColumnDirection] = useState<number>(1);

  const resetDiscountsContext = useCallback(() => {
    setCurrentPage(1);
    setRowsPerPage(5);
    setDense(false);
    setStatus('all');
    setSearchValue('');
    setSortColumnValue(SortColumn.NAME);
    setSortColumnDirection(1);
  }, [
    setCurrentPage,
    setRowsPerPage,
    setDense,
    setStatus,
    setSearchValue,
    setSortColumnValue,
    setSortColumnDirection,
  ]);

  const CONTEXT_VALUE = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      status,
      setStatus,
      searchValue,
      setSearchValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      resetDiscountsContext,
    }),
    [
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      status,
      setStatus,
      searchValue,
      setSearchValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      resetDiscountsContext,
    ]
  );

  return (
    <DiscountsContext.Provider value={CONTEXT_VALUE}>
      {children}
    </DiscountsContext.Provider>
  );
};
